import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import "./proposeButton.css"

const ProposeButton = ({ onClick, title }) => {
  const breakpoints = useBreakpoint()

  return (
    <button className="propose-button" onClick={onClick}>
      <span
        style={{ lineHeight: `1`, marginBottom: breakpoints.sm ? `0` : `20px` }}
      >
        {title}
      </span>

      <StaticImage
        src="../images/arrow.png"
        width={105}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Пред"
      />
    </button>
  )
}

export default ProposeButton
