import React from 'react'

const VoteFinal = () => {
    return (
        <div className="vote-modal__final">
            <h2 className="propose-modal__title">
                Спасибо за голосование!
            </h2>

            <p className="propose-modal__success-text">
                После окончания голосования мы сформируем список победителей.
            </p>
        </div>
    )
}


export default VoteFinal