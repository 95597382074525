import React, {useState} from 'react'
import Error from "./UI/error";
import InputMask from "react-input-mask";
import Input from "./UI/input";
import {StaticImage} from "gatsby-plugin-image";
import Verify from "./UI/verify";

const VoteAuth = ({phone, email, onSetPhone, onSetEmail, onSetVerified}) => {
    const [type, setType] = useState('email')
    const [code, setCode] = useState('')
    const [verifyCode, setVerifyCode] = useState('')
    const [sended, setSended] = useState(false)
    const [error, setError] = useState('')
    const [verified, setVerified] = useState(false)

    const onChangePhone = (event) => {
        let val = event.target.value.replace(/\D/g, '')

        setError('')
        onSetPhone(val)
    }

    const onChangeEmail = (event) => {
        setError('')
        onSetEmail(event.target.value)
    }

    const onSetVerifyCode = (value) => {
        setVerifyCode(value)

        if (code === value) {
            setVerified(true);
            onSetVerified(true)
        }
    }

    const onSendCode = (event) => {
        let isValid = true;

        if (type === 'phone') {
            if (!phone || phone.length < 11) {
                setError('Введите корректный номер')
                isValid = false
            }
        } else {
            if (!email || !email.match(/.+@.+/)) {
                setError('Укажите корректный адрес')
                isValid = false
            }
        }

        if (isValid) {
            checkContact()
        }
    }

    const onSetType = (type) => {
        setVerifyCode('')
        setError('')
        setSended(false)
        setVerified(false)

        setType(type);
    }

    const checkContact = () => {
        let requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                'value': phone ? phone : email
            })
        };

        fetch('/check-contact', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success === 'Y') {
                    setVerified(true);
                    onSetVerified(true)
                } else {
                    sendCode()
                }
            });
    }

    const sendCode = () => {
        let random = Math.random().toString().substr(2, 6)

        setCode(random)
        let requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                'type': type === 'phone' ? 'phone' : 'email',
                'value': type === 'phone' ? phone : email,
                'code': random
            })
        };

        fetch('/send-code', requestOptions)
            .then(response => response.json())
            .then(data => {
                setSended(true)
            });
    }

    return (
        <div className="propose-modal__content">
            <h3 className="propose-modal__title">Авторизация</h3>
            <p className="propose-modal__desc">для защиты от накруток</p>

            {
                !sended ?
                    <div className="propose-modal__auth-wrapper">
                        {
                            type === 'phone'
                                ?
                                <div className="propose-modal__form-group">
                                    <label className="propose-modal__label" htmlFor={type}>Введите номер
                                        телефона*</label>
                                    <Error message={error} className={'propose-modal__error'}/>
                                    <InputMask
                                        id={'phone'}
                                        className={'propose-modal__input'}
                                        mask={'+7 (999) 999-99-99'}
                                        value={phone}
                                        onChange={onChangePhone}
                                    />
                                </div>
                                :
                                <div className="propose-modal__form-group">
                                    <label className="propose-modal__label" htmlFor={type}>Введите email*</label>
                                    <Error message={error} className={'propose-modal__error'}/>
                                    <Input
                                        id={'email'}
                                        className={'propose-modal__input'}
                                        value={email}
                                        onChange={onChangeEmail}
                                    />
                                </div>
                        }

                        <button className="propose-modal__submit" onClick={onSendCode}>
                            <span className="propose-modal__submit-text">Отправить</span>
                            <span className="propose-modal__submit-icon">
                            <StaticImage
                                src="../images/arrow-small.png"
                                width={17}
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt="Отправить"
                            />
                          </span>
                        </button>
                    </div>
                    :
                    <div className="propose-modal__form-group">
                        <label
                            className="auth-modal__label"
                            htmlFor={type}
                        >
                            Введите код {type === 'phone' ? 'из смс' : 'из письма'}
                        </label>
                        <Error message={error}/>
                        <Verify onChangeCode={onSetVerifyCode} verified={verified} code={verifyCode}/>
                    </div>
            }

            <div className="auth-type--chooser">
                {
                    type === 'phone'
                        ? <span onClick={() => onSetType('email')}>Авторизоваться по email</span>
                        : <span onClick={() => onSetType('phone')}>Авторизоваться по телефону</span>
                }
            </div>

            <p className="auth-modal__info">
                * — мы не собираем ваши данные и&nbsp;не&nbsp;будем использовать их
                для рассылки&nbsp;рекламы
            </p>
        </div>
    )
}

export default VoteAuth