import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Header = () => {
  const breakpoints = useBreakpoint()

  const titleMarginLeft = () => {
    if (breakpoints.sm) return `0`
    if (breakpoints.md) return `8px`

    return `-20px`
  }

  const gridTemplateColumns = () => {
    if (breakpoints.sm) return `1fr`
    if (breakpoints.md) return `1fr 1fr`

    return `415px 1fr`
  }

  const gridTemplateAreas = () => {
    if (breakpoints.sm) return `"logo" "title" "desc"`
    if (breakpoints.md) return `"logo desc" "title title"`

    return `"logo desc" "logo title"`
  }

  return (
    <header>
      <div
        style={{
          display: `grid`,
          alignItems: `center`,
          gridTemplateColumns: gridTemplateColumns(),
          gridTemplateAreas: gridTemplateAreas(),
          margin: `0 auto`,
          maxWidth: 1440,
          padding: breakpoints.md ? `32px 16px` : `56px 150px`,
          ...(breakpoints.sm && {
            justifyContent: `center`,
          }),
        }}
      >
        <div
          style={{
            gridArea: `logo`,
            marginLeft: breakpoints.md ? `0` : `-44px`,

            ...(breakpoints.sm && {
              width: `100%`,
              textAlign: `center`,
            }),
          }}
        >
          <StaticImage
            src="../images/logo.png"
            width={415}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Народная премия zelenograd.ru"
          />
        </div>

        <p
          style={{
            paddingLeft: breakpoints.md ? `8px` : `96px`,
            paddingTop: `36px`,
            marginBottom: `40px`,
            gridArea: `desc`,
            fontSize: breakpoints.md ? `20px` : `27px`,

            ...(breakpoints.sm && {
              paddingLeft: 0,
              textAlign: `center`,
            }),
          }}
        >
          Народная премия Зеленоград.ру — это знак качества, присуждаемый
          жителями нашего города лучшим представителям бизнеса{" "}
        </p>

        <h1
          style={{
            gridArea: `title`,
            marginTop: breakpoints.md ? `24px` : `0`,
            marginLeft: titleMarginLeft(),
          }}
        >
          Выбирайте лучший бизнес Зеленограда
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
