import React, {Component, useState} from 'react'

import "../verify.css"

class Verify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                'one': '',
                'two': '',
                'three': '',
                'four': '',
                'five': '',
                'six': ''
            },
            string: ''
        }
    }

    findNextInput = (el) => {
        let selectors = document.querySelectorAll('.verify-code-item'),
            list = Array.prototype.filter.call(selectors, (item) => {
                return item.tabIndex >= "0"
            }),
            index = list.indexOf(el)

        return list[index + 1]
    }

    collectString = (state) => {
        let code = ''

        for (let k in state) {
            code += state[k]
        }

        return code
    }

    changeValue = (event) => {
        let node = event.target, id = node.id, value = node.value

        this.setState((state) => {
            state.values[id] = value

            this.props.onChangeCode(this.collectString(state.values));

            return state
        })

        if (value) {
            let nextEl = this.findNextInput(node);
            if (!!nextEl)
                nextEl.focus()
        }
    }

    render() {
        return (
            <div className="verify-code">
                <input disabled={this.props.verified} id={'one'} tabIndex="1" className="verify-code-item" maxLength={1} value={this.state.values.one}
                       onChange={this.changeValue} autoFocus={true}/>
                <input disabled={this.props.verified} id={'two'} tabIndex="2" className="verify-code-item" maxLength={1} value={this.state.values.two}
                       onChange={this.changeValue}/>
                <input disabled={this.props.verified} id={'three'} tabIndex="3" className="verify-code-item" maxLength={1} value={this.state.values.three}
                       onChange={this.changeValue}/>
                <input disabled={this.props.verified} id={'four'} tabIndex="4" className="verify-code-item" maxLength={1} value={this.state.values.four}
                       onChange={this.changeValue}/>
                <input disabled={this.props.verified} id={'five'} tabIndex="5" className="verify-code-item" maxLength={1} value={this.state.values.five}
                       onChange={this.changeValue}/>
                <input disabled={this.props.verified} id={'six'} tabIndex="6" className="verify-code-item" maxLength={1} value={this.state.values.six}
                       onChange={this.changeValue}/>
            </div>
        )
    }
}

export default Verify