import React from 'react'
import Modal from 'react-modal'
import {StaticImage} from 'gatsby-plugin-image'
import Nominate from "./Nominate"
import VoteAuth from "./VoteAuth"
import VoteFinal from "./VoteFinal"

import './voteModal.css'

const BackStep = ({nominates, step, onSetStep}) => {
    let previousStep = step - 1

    return (
        nominates.hasOwnProperty(previousStep) &&
        <button
            className='vote-modal__back'
            onClick={() => onSetStep(previousStep)}
        >
            <StaticImage
                src="../images/arrow-back.png"
                width={12}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Народная премия"
            />

            <span className='vote-modal__back-text'>{nominates[previousStep].name}</span>
        </button>
    )
}

const NextStep = ({nominates, step, onSetStep}) => {
    let nextStep = step + 1

    if (nominates.hasOwnProperty(nextStep))
        return (
            <button
                className='vote-modal__next'
                onClick={() => onSetStep(nextStep)}
            >
                <div className='vote-modal__next-text'>
                    <span className='vote-modal__next-category'>{nominates[nextStep].name}</span>
                </div>
                <StaticImage
                    src='../images/arrow.png'
                    width={70}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt='Далее'
                />
            </button>
        )
    else
        return (
            <button
                className='vote-modal__next'
                onClick={() => onSetStep('send')}
            >
                <div className='vote-modal__next-text'>
                    <span className='vote-modal__next-category'>Проголосовать</span>
                </div>
                <StaticImage
                    src='../images/arrow.png'
                    width={70}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt='Далее'
                />
            </button>
        )
}

class VoteModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false,
            phone: '',
            email: '',
            step: 1,
            nominates: {},
            votes: []
        }
    }

    componentDidMount() {
        let requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                test: 'y'
            })
        }

        fetch('/nominates', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    nominates: data.nominates,
                    votes: data.votes,
                    emptyVotes: data.emptyVotes
                })
            })
    }

    send = () => {
        if (this.state.verified === true) {
            let requestOptions = {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify({
                    votes: this.state.votes,
                    phone: this.state.phone,
                    email: this.state.email
                })
            }

            fetch('/vote', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setStep('final')
                })
        } else {
            this.setState({
                step: 'auth'
            })
        }
    }

    setStep = step => {
        if (step === 'send') {
            this.send()
        } else {
            this.setState({
                step: step
            }, this.scrollTop)
        }
    }

    scrollTop = () => {
        document.querySelector('.ReactModal__Overlay').scrollTo(0, 0)
    }

    setVote = (nominate, id, name) => {
        let votes = this.state.votes

        votes[nominate] = name
        this.setState({
            votes: votes
        })
    }

    setPhone = phone => {
        this.setState({
            phone: phone
        })
    }

    setEmail = email => {
        this.setState({
            email: email
        })
    }

    setVerified = verified => {
        this.setState({
            verified: verified
        }, this.send)
    }

    close = () => {
        this.props.onRequestClose()
        this.setState({
            step: 1,
            votes: []
        })
    }

    render() {
        return (
            <Modal
                className='vote-modal'
                isOpen={this.props.isOpen}
                closeTimeoutMS={300}
            >
                <div className='vote-modal__wrapper'>
                    <button className="vote-modal__close" onClick={this.close}>
                        <StaticImage
                            src="../images/close.png"
                            width={120}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="Закрыть"
                        />
                    </button>
                    <div className='vote-modal__header'>
                        <h3 className='vote-modal__title'>Голосование</h3>
                        <StaticImage
                            src="../images/logo-color.png"
                            width={165}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="Народная премия"
                        />
                    </div>
                    {
                        this.state.step !== 'send' && this.state.step !== 'auth' && this.state.step !== 'final' &&
                        <Nominate votes={this.state.votes} nominates={this.state.nominates} step={this.state.step} onSetVote={this.setVote}/>
                    }
                    {
                        this.state.step === 'auth' &&
                        <div className='vote-modal__body auth'>
                            <VoteAuth
                                phone={this.state.phone}
                                email={this.state.email}
                                onSetPhone={this.setPhone}
                                onSetEmail={this.setEmail}
                                onSetVerified={this.setVerified}
                            />
                        </div>
                    }
                    {
                        this.state.step === 'final' &&
                        <div className='vote-modal__body auth'>
                            <VoteFinal />
                        </div>
                    }
                    {
                        this.state.step !== 'send' && this.state.step !== 'auth' && this.state.step !== 'final' &&
                        <div className='vote-modal__footer'>
                            <BackStep {...this.state} onSetStep={this.setStep}/>
                            <NextStep {...this.state} onSetStep={this.setStep}/>
                        </div>
                    }

                </div>
            </Modal>
        )
    }
}

Modal.setAppElement("#___gatsby")
export default VoteModal