import * as React from "react"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ProposeButton from "../components/proposeButton"
import ProposeModal from "./ProposeModal"

import "./footer.css"
import VoteModal from "./voteModal";

const Footer = ({onRequestVote}) => {
  const breakpoints = useBreakpoint()

  const [isProposeModalOpen, setIsProposeModalOpen] = useState(false)

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__grid">
          <div>
            <div className="footer__cell">
              <p className="footer__text">Рекламная служба</p>
              <a className="footer__link" href="mailto:reklama@zelenograd.ru">
                reklama@zelenograd.ru
              </a>
            </div>
            <div className="footer__cell">
              <p className="footer__text">Организатор</p>
              <p className="footer__text">Редакция <a className="footer__link" href="https://zelenograd.ru">Zelenograd.ru</a></p>
              <p className="footer__text">
                <a className="footer__link" href="https://wa.me/79261008167">ватсап</a> / <a className="footer__link" href="https://t.me/zelenogradru_contact">телеграм</a>
              </p>
              <br />
              <a className="footer__link" href="mailto:award@zelenograd.ru">
                award@zelenograd.ru
              </a>
            </div>
          </div>
          <div>
            <div className="footer__cell">
              <p className="footer__text">© 2024 Зеленоград.ру</p>
            </div>
            <div className="footer__cell">
              <p className="footer__text">
                Свидетельство о регистрации
                <br />
                Эл №ФС 77-30549
              </p>
            </div>
          </div>
          <div style={{ paddingRight: `24px`, textAlign: `right` }}>
            <span
              style={{
                display: `block`,
                marginTop: `-70px`,
                ...(breakpoints.md && {
                  marginTop: `0`,
                }),
              }}
            >
              <StaticImage
                src="../images/logo-small.png"
                width={205}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Награда"
                onClick={() => setIsProposeModalOpen(true)}
              />
            </span>
          </div>
        </div>
        <ProposeButton onClick={() => setIsProposeModalOpen(true)} title='Проголосовать' />
      </div>
      <VoteModal
          isOpen={isProposeModalOpen}
          onRequestClose={() => setIsProposeModalOpen(false)}
      />
    </footer>
  )
}

export default Footer
