import React from 'react'
import Auth from "./auth";

const Nominate = ({votes, nominates, step, onSetVote}) => {
    console.log(nominates)
    const name = nominates[step].name
    const candidates = nominates[step].candidates
    return (
        <div className='vote-modal__body'>
            <h4 className='vote-modal__subtitle'>{name}</h4>
            {
                candidates.map(candidate => (
                    <div
                        className='vote-modal__candidate'
                        key={candidate.id}
                    >
                        <span className='vote-modal__radio-wrapper'>
                            <input
                                className='vote-modal__radio'
                                name='candidates'
                                type='radio'
                                id={candidate.id}
                                value={candidate.id}
                                checked={votes[name] === candidate.name}
                                onChange={() => onSetVote(name, candidate.id, candidate.name)}
                            />
                            <label
                                className="vote-modal__label"
                                htmlFor={candidate.id}
                            >
                              {candidate.name}
                            </label>
                        </span>
                        {
                            candidate.comment  &&
                            <p className='vote-modal__radio-desc'>{candidate.comment}</p>
                        }
                        {
                            candidate.link  &&
                            <a className='vote-modal__link' target='_blank' href={candidate.link} title={candidate.name}>{candidate.shortLink}</a>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Nominate